<template>
  <div class="row">
    <div class="col-md-10 offset-1">
      <div class="card form-body">
        <div class="card-body">
          <div class="row">
            <div class="col m-10"><div v-html="link"></div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import { FETCH_TRAINING_LINK } from "@/core/services/store/actions.type";

export default {
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Training links",
          route: ""
        }
      ],
      link: "",

      errors: []
    };
  },

  computed: {
    ...mapGetters(["currentUser"])
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_TRAINING_LINK).then(data => {
      this.link = data.data.link ? data.data.link : "";
    });
  },
  methods: {
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    }
    //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
    //
  }
};
</script>

<style></style>
